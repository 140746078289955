import React from "react";
import { Redirect, Route } from "react-router";
const PrivateRoute = ({ component: Component, ...rest }) => {
  const a_token = JSON.parse(localStorage.getItem("persistantState"));
  const token = a_token?.user?.token;
  return (
    <Route
      {...rest}
      render={() => {
        return token ? <Component /> : <Redirect to={{ pathname: "/login" }} />;
      }}
      
    />
  );
};

export default PrivateRoute;
