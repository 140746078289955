import { combineReducers } from "redux";
import { userReducer } from "../reducers/userReducers";
import { changeState } from "./sidebarReducer";

import { alertReducer } from "./alertReducer";
import companyReducer from "./companyReducer";
import listingReducer from "./listingReducer";
import resourceReducer from "./resourceReducer";
import { resumeBuilderForm } from "./resumebuilder";
import usersReducer from "./usersReducer";
import msaReducer from "./msaReducer";
const rootReducer = combineReducers({
  user: userReducer,
  alert: alertReducer,
  sidebar: changeState,
  companyDetails: companyReducer,
  listings: listingReducer,
  resourceDetails: resourceReducer,
  resumebuilder: resumeBuilderForm,
  usersReducer: usersReducer,
  msa: msaReducer,
});

export default rootReducer;
