import {
  BUYER_DETAILS,
  SELLER_DETAILS,
  USER_LOGIN,
  USER_LOGOUT,
} from "./actionTypes";
// import {setUser,setUserToLocalStorage} from '../../redux/actions/userActions';
import {
  API_ENDPOINT_BUYER_DETAIL_BY_ID,
  API_ENDPOINT_BUYER_REGISTER,
  API_ENDPOINT_LOGIN,
  API_ENDPOINT_SELLER_DETAIL_BY_ID,
  API_ENDPOINT_SELLER_REGISTER,
  INTERVIEW_CREATE,
  RESHEDULE_INTERVIEW_CREATE,
} from "../../services/apiEndPoints";

import { fetchData } from "./apiRequest";
import { authService } from "./apiRequest";
const accessToken = localStorage.getItem("token");
export const login = (values) => {
  return (dispatch) => {
    return authService.fetchData("POST", API_ENDPOINT_LOGIN, values).then(
      (data) => {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { user: data },
        });

        return Promise.resolve();
      },
      (error) => {
        dispatch({
          type: LOGIN_FAIL,
        });

        return Promise.reject();
      }
    );
  };
};

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const CreateBuyer = (data, access_token) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchData("POST", API_ENDPOINT_BUYER_REGISTER, data, access_token)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
};

export const CreateSeller = (data, access_token) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchData("POST", API_ENDPOINT_SELLER_REGISTER, data, access_token)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
};

export const setUser = (user, tokens) => {
  // console.log("object", tokens);
  if (tokens) {
    console.log("user", user);
    const { access_token, refresh_token } = tokens;
    return {
      type: USER_LOGIN,
      payload: {
        token: access_token,
        refresh_token: refresh_token,
        user_id: user.user_id,
      },
    };
  } else {
    return {
      type: USER_LOGIN,
      payload: user,
    };
  }
};

export const userLogout = () => (dispatch) => {
  dispatch({ type: USER_LOGOUT });
  removeUserToLocalStorage();
};

export const loadFromLocalStorage = () => {
  try {
    const serialisedState = localStorage.getItem("persistantState");

    if (serialisedState === null) return undefined;
    return JSON.parse(serialisedState);
  } catch (e) {
    console.warn(e);
    return "test";
  }
};
export const saveToLocalStorage = (state) => {
  try {
    const serialisedState = JSON.stringify(state);
    localStorage.setItem("persistantState", serialisedState);
  } catch (e) {
    console.warn(e);
  }
};

export const setUserToLocalStorage = (user, tokens) => {
  const string = tokens.access_token.replaceAll('^"|"$', "");
  localStorage.setItem("token", JSON.stringify(string));
};

export const logoutUser = () => {
  removeUserToLocalStorage();
};

export const removeUserToLocalStorage = () => {
  localStorage.setItem("token", "[]");
  localStorage.setItem("persistantState", "[]");
};

export const SellerDetails = (id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchData("GET", API_ENDPOINT_SELLER_DETAIL_BY_ID + id, null, accessToken)
        .then((response) => {
          dispatch({
            type: SELLER_DETAILS,
            sellerDetails: response?.data,
          });
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
};

export const BuyerDetails = (id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchData("GET", API_ENDPOINT_BUYER_DETAIL_BY_ID + id, null, accessToken)
        .then((response) => {
          dispatch({
            type: BUYER_DETAILS,
            buyerDetails: response?.data,
          });
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
};

export const SheduleInterview = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchData("POST", INTERVIEW_CREATE, data, accessToken)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
};
export const ResheduleInterview = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchData("POST", RESHEDULE_INTERVIEW_CREATE, data, accessToken)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
};
