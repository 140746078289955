import { GET_MSA_LIST, GET_ZX_POC_LIST } from "../actions/actionTypes";

const INITIAL_STATE = {
  msa: {
    msa_list: [],
    zx_poc_list: [],
  },
};

const msaReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_MSA_LIST: {
      return {
        ...state,
        msa: {
          ...state.msa,
          msa_list: action?.data,
        },
      };
    }
    case GET_ZX_POC_LIST: {
      return {
        ...state,
        msa: {
          ...state.msa,
          zx_poc_list: action?.data,
        },
      };
    }

    default:
      return state;
  }
};

export default msaReducer;
