import { USER_LOGIN, USER_LOGOUT } from "../actions/actionTypes";
export const userReducer = (state = { isLoggedIn: false }, action) => {
  const { type, payload } = action;

  switch (type) {
    case USER_LOGIN:
      return {
        isLoggedIn: true,
        token: payload?.token,
        refresh_token: payload?.refresh_token,
        user_id: payload?.user_id,
      };

    case USER_LOGOUT:
      return {};

    default:
      return state;
  }
};

export default userReducer;
