import {
  CREATE_EDUCATION_DETAILS,
  CREATE_EXPERIENCE_DETAILS,
  CREATE_KEY_SKILLS_DETAILS,
  CREATE_PROFILE_DETAILS,
  CREATE_PROJECT_DETAILS,
  CREATE_USER_DETAILS,
  DELETE_ALL_DETAILS,
  DELETE_EDUCATION_DETAILS,
  DELETE_EXPERIENCE_DETAILS,
  DELETE_PROJECT_DETAILS,
} from "../actions/actionTypes";

const INITIAL_STATE = {
  college_details: [],
  work_experience: [],
  project_details: [],
  skills: [],
  profile: "",
};

export const resumeBuilderForm = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CREATE_USER_DETAILS: {
      return {
        ...state,
        ...action.createUserDetails,
      };
    }
    case CREATE_EDUCATION_DETAILS: {
      return {
        ...state,
        college_details:
          state.college_details?.length > 0
            ? [...state.college_details, action.createEducationDetails]
            : [action.createEducationDetails],
      };
    }
    case DELETE_EDUCATION_DETAILS: {
      const newState = state.college_details.filter(
        (val, ind) => ind !== action.index
      );
      return {
        ...state,
        college_details: newState,
      };
    }
    case CREATE_EXPERIENCE_DETAILS: {
      return {
        ...state,
        work_experience:
          state.work_experience?.length > 0
            ? [...state.work_experience, action.createExperienceDetails]
            : [action.createExperienceDetails],
      };
    }
    case DELETE_EXPERIENCE_DETAILS: {
      const newState = state.work_experience.filter(
        (val, ind) => ind !== action.index
      );
      return {
        ...state,
        work_experience: newState,
      };
    }
    case CREATE_PROJECT_DETAILS: {
      return {
        ...state,
        project_details:
          state.project_details?.length > 0
            ? [...state.project_details, action.createProjectDetails]
            : [action.createProjectDetails],
      };
    }
    case DELETE_PROJECT_DETAILS: {
      const newState = state.project_details.filter(
        (val, ind) => ind !== action.index
      );
      return {
        ...state,
        project_details: newState,
      };
    }
    case CREATE_KEY_SKILLS_DETAILS: {
      return {
        ...state,
        skills: action.createKeySkillDetails,
      };
    }
    case CREATE_PROFILE_DETAILS: {
      return {
        ...state,
        profile: action.summeryDetails,
      };
    }
    case DELETE_ALL_DETAILS: {
      return {};
    }
    default:
      return state;
  }
};
