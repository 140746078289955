import {
  GET_CIRTIFICATION_LIST,
  GET_CITY_LIST,
  GET_LOCATIONS_LIST,
  GET_SKILLS_DETAILS,
  GET_SKILLS_LIST,
  GET_STATE_LIST,
} from "../actions/actionTypes";

const INITIAL_STATE = {
  listings: {
    locations: {
      data: [],
      isLoading: false,
    },
    skills: {
      data: [],
      isLoading: false,
    },
    cirtifications: {
      data: [],
      isLoading: false,
    },
    cities: {
      data: [],
      isLoading: false,
    },
    states: {
      data: [],
      isLoading: false,
    },
    SkillDetails: {
      data: [],
      isLoaded: false,
    },
  },
  isLoading: false,
};

const listingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_LOCATIONS_LIST: {
      return {
        ...state,
        listings: {
          ...state.listings,
          locations: { data: action.locationsList, isLoading: true },
        },
        isLoading: true,
      };
    }
    case GET_SKILLS_LIST: {
      return {
        ...state,
        listings: {
          ...state.listings,
          skills: { data: action.skillsList, isLoading: true },
        },
        isLoading: true,
      };
    }
    case GET_SKILLS_DETAILS: {
      return {
        ...state,
        listings: {
          ...state.listings,
          SkillDetails: { data: action.SkillDetails, isLoading: true },
        },
        isLoading: true,
      };
    }
    case GET_CIRTIFICATION_LIST: {
      return {
        ...state,
        listings: {
          ...state.listings,
          cirtifications: { data: action.cirtificationList, isLoading: true },
        },
        isLoading: true,
      };
    }
    case GET_CITY_LIST: {
      return {
        ...state,
        listings: {
          ...state.listings,
          cities: { data: action.data, isLoading: true },
        },
        isLoading: true,
      };
    }

    case GET_STATE_LIST: {
      return {
        ...state,
        listings: {
          ...state.listings,
          states: { data: action.data, isLoading: true },
        },
        isLoading: true,
      };
    }

    case "REST_LISTING": {
      return {
        ...state,
        listings: {
          locations: {
            data: [],
            isLoading: false,
          },
          skills: {
            data: [],
            isLoading: false,
          },
          cirtifications: {
            data: [],
            isLoading: false,
          },
          cities: {
            data: [],
            isLoading: false,
          },
        },
        isLoading: false,
      };
    }

    default:
      return state;
  }
};

export default listingReducer;
