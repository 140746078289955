import {
  RESOURCE_DETAILS,
  RESOURCE_PENDING_LIST,
  RESOURCE_REJECTED_LIST,
  RESOURCE_VERIFIED_LIST,
  REST_COMPANY_INFO,
} from "../actions/actionTypes";

const INITIAL_STATE = {
  resourceDetails: {
    approvedResource: [],
    rejectedResource: [],
    pendingResource: [],
  },
  isLoaded: false,
};

const resourceReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RESOURCE_DETAILS: {
      return {
        ...state,
        resourceDetails: action.resourceDetails,
        isLoaded: true,
      };
    }
    case REST_COMPANY_INFO: {
      return {
        ...state,
        resourceDetails: [],
        isLoaded: false,
      };
    }
    case RESOURCE_VERIFIED_LIST: {
      return {
        ...state,
        resourceDetails: {
          ...state.resourceDetails,
          approvedResource: action.data,
        },
      };
    }
    case RESOURCE_PENDING_LIST: {
      return {
        ...state,
        resourceDetails: {
          ...state.resourceDetails,
          pendingResource: action.data,
        },
      };
    }
    case RESOURCE_REJECTED_LIST: {
      return {
        ...state,
        resourceDetails: {
          ...state.resourceDetails,
          rejectedResource: action.data,
        },
      };
    }
    default:
      return state;
  }
};
export default resourceReducer;
