import {
  ALERT_SUCCESS,
  ALERT_FAILURE,
  ALERT_CLEAR,
} from "../actionTypes/alertTypes";

export const alertReducer = (state = {}, actions) => {
  const { type, payload } = actions;
  switch (type) {
    case ALERT_SUCCESS:
      return {
        message: payload.message,
      };
    case ALERT_FAILURE:
      return {
        message: payload.message,
      };
    case ALERT_CLEAR:
      return {};
    default:
      return state;
  }
};
