import { GET_BUYER_USERS, GET_SELLER_USERS } from "../actions/actionTypes";

const INITIAL_STATE = {
  usersDetails: {
    sellerUser: [],
    buyerUser: [],
  },
  isLoaded: false,
};

const usersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_SELLER_USERS: {
      return {
        ...state,
        usersDetails: {
          ...state.usersDetails,
          sellerUser: action.sellerDetails,
        },
        isLoaded: false,
      };
    }
    case GET_BUYER_USERS: {
      return {
        ...state,
        usersDetails: {
          ...state.usersDetails,
          buyerUser: action.buyerDetails,
        },
        isLoaded: false,
      };
    }

    default:
      return state;
  }
};
export default usersReducer;
