import axios from "axios";

const axiosApiInstance = axios.create();
export const Baseurl = {
  url: process.env.REACT_APP_API_URL,
};

axiosApiInstance.interceptors.request.use(
  async (config) => {
    const a_token = JSON.parse(localStorage.getItem("token"));
    config.headers = {
      Authorization: `Bearer ${a_token}`,
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosApiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    return new Promise((resolve, reject) => {
      const originalReq = err.config;
      if (
        err.response.status === 403 &&
        err.config &&
        !err.config.__isRetryRequest
      ) {
        originalReq._retry = true;
        const token = JSON.parse(localStorage.getItem("persistantState"));
        const obj = {};
        obj.token = token?.user?.refresh_token;
        axiosApiInstance({
          method: "POST",
          url: Baseurl.url + "/users/auth/refresh",
          data: obj,
        })
          .then((res) => {
            localStorage.setItem("token", JSON.stringify(res.data.data.token));
            token["user"]["token"] = res.data.data.token;

            localStorage.setItem("persistantState", JSON.stringify(token));
            originalReq.headers[
              "Authorization"
            ] = `Bearer ${res.data.data.token}`;
            resolve(axiosApiInstance(originalReq));
          })
          .catch((err) => console.log("_ree_", err));
      }
      return Promise.reject(err);
    });
  }
);

export const fetchData = (method, endpoint, data, access_token) => {
  let requestParams = {
    method: method,
    url: Baseurl.url + endpoint,
    data: data,
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  };

  return new Promise((resolve, reject) => {
    axiosApiInstance(requestParams)
      .then((resp) => {
        if (resp.status) {
          resolve(resp.data);
        } else {
          resolve(resp.data);
        }
      })
      .catch((err) => {
        console.log("_error", err);
        return reject(err.response);
      });
  });
};

export const authService = {
  fetchData,
};

export default authService;
