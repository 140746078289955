import {
  BUYER_DETAILS,
  COMPANY_DETAILS,
  GET_COMPANY_LISTING,
  GET_PENDING_COMPANY_LISTING,
  RESET_COMPANY_LIST,
  SELLER_DETAILS,
} from "../actions/actionTypes";

const INITIAL_STATE = {
  CompanyListing: {
    companyList: [],
    verfirdCompany: [],
    pendingCompany: [],
    isLoaded: false,
  },
  CompanyDetails: {
    companyDetails: [],
    isLoaded: false,
  },
  SellerDetails: [],
  BuyerDetails: [],
  isLoaded: false,
};

const companyReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_COMPANY_LISTING: {
      return {
        ...state,
        CompanyListing: {
          ...state.CompanyListing,
          companyList: action.companyListing,
          isLoaded: true,
        },
      };
    }
    case GET_PENDING_COMPANY_LISTING: {
      return {
        ...state,
        CompanyListing: {
          ...state.CompanyListing,
          pendingCompany: action.companyListing,
          isLoaded: true,
        },
      };
    }
    case COMPANY_DETAILS: {
      return {
        ...state,
        CompanyDetails: action.companyDetails,
        isLoaded: true,
      };
    }
    case SELLER_DETAILS: {
      return {
        ...state,
        SellerDetails: action.sellerDetails,
        isLoaded: true,
      };
    }
    case BUYER_DETAILS: {
      return {
        ...state,
        BuyerDetails: action.buyerDetails,
        isLoaded: true,
      };
    }
    case RESET_COMPANY_LIST: {
      // console.log("Reset Listing")
      return {
        ...state,
        CompanyListing: {
          ...state.CompanyListing,
          companyList: [],
          isLoaded: false,
        },
      };
    }

    case "REST_COMPANY_INFO": {
      return {
        ...state,
        CompanyDetails: [],
        isLoaded: false,
      };
    }

    default:
      return state;
  }
};

export default companyReducer;
