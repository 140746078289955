export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGOUT = "USER_LOGOUT";

export const RESOURCE_DETAILS = "RESOURCE_DETAILS";
export const GET_LOCATIONS_LIST = "GET_LOCATIONS_LIST";
export const GET_CITY_LIST = "GET_CITY_LIST";
export const GET_STATE_LIST = "GET_STATE_LIST";
export const GET_SKILLS_LIST = "GET_SKILLS_LIST";
export const GET_CIRTIFICATION_LIST = "GET_CIRTIFICATION_LIST";
export const SELLER_DETAILS = "SELLER_DETAILS";
export const BUYER_DETAILS = "BUYER_DETAILS";
export const GET_SKILLS_DETAILS = "GET_SKILLS_DETAILS";
// RESUME BUILDER
export const CREATE_USER_DETAILS = "CREATE_USER_DETAIL";
export const CREATE_EDUCATION_DETAILS = "CREATE_EDUCATION_DETAILS";
export const CREATE_EXPERIENCE_DETAILS = "CREATE_EXPERIENCE_DETAILS";
export const CREATE_PROJECT_DETAILS = "CREATE_PROJECT_DETAILS";
export const CREATE_KEY_SKILLS_DETAILS = "CREATE_KEY_SKILLS_DETAILS";
export const CREATE_PROFILE_DETAILS = "CREATE_PROFILE_DETAILS";
export const DELETE_EDUCATION_DETAILS = "DELETE_EDUCATION_DETAILS";
export const DELETE_EXPERIENCE_DETAILS = "DELETE_EXPERIENCE_DETAILS";
export const DELETE_PROJECT_DETAILS = "DELETE_PROJECT_DETAILS";
export const DELETE_ALL_DETAILS = "DELETE_ALL_DETAILS";

// resources
export const RESOURCE_VERIFIED_LIST = "RESOURCE_VERIFIED_LIST";
export const RESOURCE_REJECTED_LIST = "RESOURCE_REJECTED_LIST";
export const RESOURCE_PENDING_LIST = "RESOURCE_PENDING_LIST";

// company list
export const GET_COMPANY_LISTING = "GET_COMPANY_LISTING";
export const GET_COMPANY_LISTING_DUPLICATE = "GET_COMPANY_LISTING_DUPLICATE";
export const COMPANY_DETAILS = "COMPANY_DETAILS";
export const RESET_COMPANY_LIST = "RESET_COMPANY_LIST";
export const REST_COMPANY_INFO = "REST_COMPANY_INFO";
export const GET_VERIFIED_COMPANY_LISTING = "GET_VERIFIED_COMPANY_LISTING";
export const GET_PENDING_COMPANY_LISTING = "GET_PENDING_COMPANY_LISTING";
export const GET_REJECTED_COMPANY_LISTING = "GET_REJECTED_COMPANY_LISTING";
export const GET_SELLER_USERS = "GET_SELLER_USERS";
export const GET_BUYER_USERS = "GET_BUYER_USERS";

// msa
export const GET_MSA_LIST = "GET_MSA_LIST";
export const GET_ZX_POC_LIST = "GET_ZX_POC_LIST";
